<template>
  <div>
    <v-container
      fill-height
      fluid
      grid-list-xl
    >
      <v-layout
        justify-center
        wrap
      >
        <v-flex
          md12
        >
          <material-card
            color="green"
            :title="place.name"
            :text="place.address"
          >
            <v-data-table
              :headers="headers"
              :items="machines"
              hide-actions
            >
              <template
                slot="headerCell"
                slot-scope="{ header }"
              >
                <span
                  class="subheading font-weight-light text-success text--darken-3"
                  v-text="header.text"
                />
              </template>
              <template
                slot="items"
                slot-scope="{ item }"
              >
                <td style="max-width=40px">
                  <v-btn title="Подробнее к мэппингу" color="#1867c0!important" small flat icon :to="{ name: 'Торговый автомат', params: { id: item.id } }" >
                    <v-icon>
                      mdi-alert-circle-outline
                    </v-icon>
                  </v-btn>
                  <v-btn title="Редактировать торговый автомат" color="primary" small flat icon @click="pickToEditMachine(item)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </td>
                <td>{{ item.name }}</td>
                <td>{{ item.serialNumber }}</td>
                <td>{{ item.type }} </td>
                <td>{{ item.condition }} </td>
              </template>
            </v-data-table>
          </material-card>
        </v-flex>
        
      </v-layout>
    </v-container>
    <v-container fluid>
      <v-layout row wrap>
          <v-btn title="Добавить новый автомат" color="#66bb6a" @click="addMachineDialog = true">Добавить</v-btn>
      </v-layout>
    </v-container>
  <v-dialog v-model="addMachineDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Новый торговый автомат</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="addMachineDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
                        
                        
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Название в панели администратора" 
                                    v-model="newVendingMachine.name" 
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Местоположение торгового автомата</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Местоположение отображается в приложении пользователя" 
                                    v-model="newVendingMachine.description" 
                                    required
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Описание" 
                                  v-model="newVendingMachine.serialNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Ключ терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Введите ключ от ридера" 
                                  v-model="newVendingMachine.blockchainKey"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер автомата</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер автомата" 
                                  v-model="newVendingMachine.serialVendingNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="newVendingMachine.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите тип автомата"
                                :items="types"
                                :rules="rules.required"
                                v-model="newVendingMachine.type"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Состояние</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите состояние"
                                :items="conditions"
                                :rules="rules.required"
                                v-model="newVendingMachine.condition"></v-select>
                  </v-flex>
              </v-layout>

              <!-- <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Точка продажи</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите точку продажи"
                                :items="places"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                v-model="newVendingMachine.placeId"></v-select>
                  </v-flex>
              </v-layout> -->

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Количество кофейных ячеек</v-flex>
                  <v-flex xs8>
                      <v-text-field 
                                  label="Количество кофейных ячеек" 
                                  v-model="newVendingMachine.coffeeCellsAmount"></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Количество снековых А ячеек</v-flex>
                  <v-flex xs8>
                      <v-text-field 
                                  label="Количество снековых A ячеек" 
                                  v-model="newVendingMachine.snackACellsAmount"></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Количество снековых B ячеек</v-flex>
                  <v-flex xs8>
                      <v-text-field 
                                  label="Количество снековых B ячеек" 
                                  v-model="newVendingMachine.snackBCellsAmount"></v-text-field>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="addMachine" class="white--text" :disabled="!valid">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="addMachineDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>
  <v-dialog v-model="editMachineDialog" max-width="600">
      <v-card>
          <v-toolbar flat color="#66bb6a">
            <v-toolbar-title left color="blue" class="text--white">Редактировать торговый автомат</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="editMachineDialog = false">
                <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
        <v-card-text>
          <v-form ref="form" v-model="valid">
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Название</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Название в панели администратора" 
                                    v-model="editVendingMachine.name" 
                                    required
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Местоположение торгового автомата</v-flex>
                  <v-flex xs8>
                      <v-text-field :rules="rules.required"
                                    label="Местоположение отображается в приложении пользователя" 
                                    v-model="editVendingMachine.description" 
                                    required
                                    ></v-text-field>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер устройства" 
                                  v-model="editVendingMachine.serialNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Ключ терминала</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Введите ключ от терминала" 
                                  v-model="editVendingMachine.blockchainKey"></v-textarea>
                  </v-flex>
              </v-layout>
               <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Серийный номер автомата</v-flex>
                  <v-flex xs8>
                      <v-textarea :rules="rules.required"
                                  label="Серийный номер автомата" 
                                  v-model="editVendingMachine.serialVendingNumber"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Номер венданалитики</v-flex>
                  <v-flex xs8>
                      <v-textarea 
                                  label="Серийный номер в венданалитике" 
                                  v-model="editVendingMachine.vendAnaliticaId"></v-textarea>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Тип</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите тип автомата"
                                :items="types"
                                :rules="rules.required"
                                v-model="editVendingMachine.type"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Состояние</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите состояние"
                                :items="conditions"
                                :rules="rules.required"
                                v-model="editVendingMachine.condition"></v-select>
                  </v-flex>
              </v-layout>

              <v-layout wrap>
                  <v-flex xs4 class="font-weight-bold">Точка продажи</v-flex>
                  <v-flex xs8>
                      <v-select label="Выберите точку продажи"
                                :items="places"
                                item-text="name"
                                item-value="id"
                                :rules="rules.required"
                                v-model="editVendingMachine.placeId"></v-select>
                  </v-flex>
              </v-layout>
              <v-layout wrap>
                  <v-spacer></v-spacer>
                  <v-btn color="green darken-1" @click="editMachine" class="white--text" :disabled="!valid">Сохранить</v-btn>
                  <v-btn color="red darken-1" @click.native="editMachineDialog = false" class="white--text">Отмена</v-btn>
              </v-layout>
          </v-form>
        </v-card-text>
      </v-card>
  </v-dialog>
  </div>
</template>

<script>
export default {
  data: () => ({
    id: null,
    place: {},
    places: [],
    valid: false,
    addMachineDialog: false,
    editMachineDialog: false,
    addPlaceDialog: false,
    headers: [
      {
        sortable: false,
        text: '',
      },
      {
        sortable: false,
        text: 'Название',
        value: 'name'
      },
      {
        sortable: false,
        text: 'Серийный номер',
        value: 'serialNumber'
      },
      {
        sortable: false,
        text: 'Тип',
        value: 'type'
      },
      {
        sortable: false,
        text: 'Состояние',
        value: 'condition'
      }
    ],
    machines: [
      
    ],
    currencyItems: ["Рубль", "Доллар", "Евро"],
    newPlace: {
      id: 0,
      address: null,
      name: null,
      currency: null
    },

    rules: {
        required: [v => !!v || 'Поле обязательно',],
    },
    newVendingMachine: {
      id: 0,
      serialNumber: null,
      name: null,
      type: null,
      condition: null,
      placeId: -1,
      vendAnaliticaId: null,
      coffeeCellsAmount: 0,
      snackACellsAmount: 0,
      snackBCellsAmount: 0
    },
    editVendingMachine: {},
    types: ['Снековый автомат', 'Кофейный автомат'],
    conditions: ['Исправный ТА','ТА в состоянии ошибки','ТА с заканчивающимися ингредиентами', 'Отсоединенный'],
  }),
  created (){
    this.id = this.$route.params.id;
    this.newVendingMachine.placeId = this.id
  },
  mounted () {
    this.getPlace();
    this.getPlaces()
    this.getMachines();
  },
  methods: {
    getPlace (){
      this.$http.get('/places/' + this.id)
        .then(response => {
          this.place = response.data
          console.log(this.place)
        })
    },
    getPlaces (){
      this.$http.get('/places')
        .then(response => {
          this.places = response.data
          console.log(this.items)
        })
    },
    getMachines(){
      this.$http.get('/places/' + this.id + '/vendingmachines')
        .then(response => {
          this.machines = response.data
          console.log(this.place)
        })
    },
    addMachine(){
      this.$http.post('/vendingmachines', this.newVendingMachine).
          then(response => {
            this.addMachineDialog = false;
            this.getMachines();
          }).
          catch(e => {
              console.log(e)
          });
    },
    pickToEditMachine(item){
      this.editVendingMachine = item;
      this.editMachineDialog = true;
    },
    editMachine(){
      this.$http.put('/vendingmachines/'+this.editVendingMachine.id, this.editVendingMachine).
          then(response => {
            this.editMachineDialog = false;
            //this.getMachines();
          }).
          catch(e => {
              console.log(e)
          });
    }
  }
}
</script>
